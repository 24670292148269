import { render, staticRenderFns } from "./mega_menu_dynamic_hero_search.vue?vue&type=template&id=ca6c9da2"
import script from "./mega_menu_dynamic_hero_search.vue?vue&type=script&lang=js"
export * from "./mega_menu_dynamic_hero_search.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/root/containers/hermes_xenoglosso/components/core/search.vue').default,HyperLink: require('/root/containers/hermes_xenoglosso/components/core/hyperLink.vue').default})
